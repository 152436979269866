import React from "react";
import "./mainBlock.css";
import "../common.css";
import { DiscordLogo } from "../../icons/Discord";
import { SolanaLogo } from "../../icons/Solana";
import { TwitterLogo } from "../../icons/Twitter";
import { Grid } from "@mantine/core";

export const MainBlock = () => (
  <div className="mb-wrapper">
    <div className="mb-heading">
      <img src="https://raw.githubusercontent.com/SolWorks-Dev/resources/master/src/images/landing/city.gif" width={200} />
      <br />
      Think. Make. Solve.
    </div>
    <div className="mb-subtitle">— What we do</div>
    <div className="mb-desc">
      <a href="https://twitter.com/SolWorks_" className="mb-desc-styled">
        @SolWorks
      </a>{" "}
      build tools for developers and protocols for users on Solana.
    </div>
    <Grid gutter={'xl'} justify='space-evenly' style={{width: '100%', marginTop: '10px'}}>
        <Grid.Col xs={12} sm={6} md={6} lg={6} style={{display: 'contents'}}>
            <PrimaryButton
                url="https://discord.com/invite/sujiko"
                text="Join Discord"
                icon={<DiscordLogo size={28} />}
            />
        </Grid.Col>
        <Grid.Col xs={12} sm={6} md={6} lg={6} style={{display: 'contents'}}>
            <PrimaryButton
                url="https://twitter.com/SolWorks_"
                text="Follow us"
                icon={<TwitterLogo size={28}/>}
            />
        </Grid.Col>
    </Grid>
    <a
      href="https://solana.com"
      target="_blank"
      rel="noreferrer"
      className="mb-building-on-text rise-on-hover-150"
    >
      Building on
      <SolanaLogo />
    </a>
  </div>
);

const PrimaryButton = ({
  url,
  text,
  icon,
}: {
  url: string;
  text: string;
  icon: JSX.Element;
}) => (
  <a
    className="mb-button rise-on-hover-150 center-children-row"
    href={url}
    target="_blank"
    rel="noreferrer"
  >
    <div className="mb-button-text center-children-row">
      <span>{text} </span>
      <span style={{ paddingLeft: "12px" }}>{icon}</span>
    </div>
  </a>
);
