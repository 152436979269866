import { Grid, Tooltip } from "@mantine/core";
import { AppCard } from "../AppCard/AppCard";
import "../common.css";
import { Header } from "../Header/Header";
import { MainBlock } from "../MainBlock/MainBlock";
import "./page.css";

export const Page = () => (
  <div>
    <div className="body">
      <Header />
      <div style={{ marginTop: "108px" }}>
        <MainBlock />
      </div>
    </div>
    <Divider />
    <div className="body">
      <div style={{ marginTop: "42px" }}>
        <Heading text="Apps" />
        <Grid gutter={"xl"}>
          <Grid.Col sm={12} md={6} lg={6} style={{ marginTop: "28px" }}>
            <AppCard
              emoji="📁"
              url="https://solapps.dev"
              title="SolApps"
              description="A mobile friendly application directory for the Solana ecosystem."
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6} lg={6} style={{ marginTop: "28px" }}>
            <AppCard
              emoji="💸"
              url=""
              title="SolDisperse"
              description="SOL or SPL token bulk distributor. Supports .csv and manual entry."
              comingSoon
            />
          </Grid.Col>
        </Grid>
      </div>
      <div style={{ marginTop: "28px" }}>
        <Heading text="Tools" />
        <Grid gutter={"xl"}>
          <Grid.Col sm={12} md={12} lg={12} style={{ marginTop: "28px" }}>
            <AppCard
              emoji="🧰"
              url="https://soltoolkit.dev"
              title="SolToolkit"
              description="The Swiss Army knife for Solana devs - UI, JS lib and CLI."
            />
          </Grid.Col>
          <Grid.Col sm={12} md={12} lg={12} style={{ marginTop: "28px" }}>
            <AppCard
              emoji="👣"
              url="https://solworks.notion.site/15acf5c8403b4361a0118aea23e39b0a"
              title="Footpath"
              description="A fully customizable NFT whitelist application process. Integrated with Discord and Twitter verification. Used by Sujiko."
            />
          </Grid.Col>
          <Grid.Col sm={12} md={12} lg={12} style={{ marginTop: "28px" }}>
            <AppCard
              emoji="📚"
              url="https://github.com/SolWorks-Dev/col8"
              title="Col8"
              description="An open-sourced on-chain level 2 orderbook for NFT collections. Used by Sujiko."
            />
          </Grid.Col>
        </Grid>
      </div>

      <div style={{ marginTop: "28px" }}>
        <Heading text="Protocols" />
        <Grid gutter={"xl"}>
          <Grid.Col span={12} style={{ marginTop: "28px" }}>
            <AppCard
              emoji="🍣"
              url="https://sujiko.trade"
              title="Sujiko"
              description="A first-of-its-kind protocol enabling long and short exposure of major NFT collections built on Solana."
            />
          </Grid.Col>
          <Grid.Col span={12} style={{ marginTop: "28px" }}>
            <Tooltip label="Coming soon" style={{ width: "100%" }}>
              <AppCard
                emoji="🌃"
                url=""
                title="Nite"
                description="An algorithmic, autonomous interest rate protocol built on Aptos akin to Aave, Compound and Solend."
                comingSoon
              />
            </Tooltip>
          </Grid.Col>
        </Grid>
      </div>
    </div>
  </div>
);

const Divider = () => <div className="divider" />;

interface HeadingProps {
  text: string;
}
const Heading = ({ text }: HeadingProps) => (
  <div className="heading">{text}</div>
);
