import { Center, Tooltip } from "@mantine/core";
import React from "react";
import "./header.css";
import "../common.css";
import { TwitterLogo } from "../../icons/Twitter";
import { DiscordLogo } from "../../icons/Discord";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faBook, 
  // faBlog 
} from "@fortawesome/free-solid-svg-icons";

export const Header = () => (
  <header>
    <div className="header">
      <Logo />
      <div className="links">
        {/* <ExternalLink
          icon={<FontAwesomeIcon icon={faBlog} />}
          url="https://www.wordcelclub.com/solworks.sol"
        /> */}
        <ExternalLink
          icon={<FontAwesomeIcon icon={faBook} />}
          url="https://docs.solworks.dev"
        />
        <ExternalLink
          icon={<TwitterLogo size={24} />}
          url="https://twitter.com/SolWorks_"
        />
        <ExternalLink
          icon={<DiscordLogo size={24} />}
          url="https://discord.com/invite/sujiko"
        />
      </div>
    </div>
  </header>
);

interface ExternalLinkProps {
  url: string;
  text?: string;
  icon?: React.ReactNode;
}

const ExternalLink = ({ text, url, icon }: ExternalLinkProps) => {
  return (
    <a
      className="menu-link rise-on-hover-150"
      href={url}
      target="_blank"
      rel="noreferrer"
    >
      {icon ? icon : text}
    </a>
  );
};

interface ComingSoonWrapperProps {
  child: JSX.Element;
}

export const ComingSoonWrapper = ({ child }: ComingSoonWrapperProps) => (
  <Tooltip label="Coming soon" className="hide-on-mobile">
    {child}
  </Tooltip>
);

const Logo = () => (
  <a
    className="logo-wrapper glow-on-hover no-bg px-18"
    href="https://solworks.dev/"
  >
    <h1 className="logo">
      <Center inline>
        <LogoIcon />
        <span className="logo-text">SolWorks</span>
      </Center>
    </h1>
  </a>
);

const LogoIcon = ({ size = 22 }) => (
  <svg
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 257.61 176.51"
    width={`${size}px`}
    height={`${size}px`}
  >
    <defs>
      <style>
        {".cls-1{fill:#fff;stroke:#fff;stroke-miterlimit:10;stroke-width:3px}"}
      </style>
    </defs>
    <g id="Layer_1-2">
      <path
        className="cls-1"
        d="m79.1 3.65 104.45 123.84M79.1 3.65 2.32 174.5l154.22.51L79.1 3.65zM206.92 68.01l-47.94 106.68 96.3.32-48.36-107z"
      />
    </g>
  </svg>
);
