import { Badge, Grid, MediaQuery, Title, Tooltip } from "@mantine/core";
import React from "react";
import "../common.css";
import "./appCard.css";

interface AppCardProps {
  emoji: string;
  title: string;
  description: string;
  url: string;
  comingSoon?: boolean;
}

export const AppCard = ({
  emoji,
  title,
  description,
  url,
  comingSoon = false,
}: AppCardProps) => {
  if (comingSoon) {
    return (
      <Tooltip label="Coming soon" style={{ width: "100%" }}>
        <div
          className="ac-outline glow-on-hover no-bg white-text-on-hover"
          style={{ textDecoration: "none" }}
        >
          <Grid align={"center"} style={{ marginTop: "-20px" }}>
            <Title order={2} style={{ paddingRight: "25px" }}>
              {emoji}
            </Title>
            <Title order={3} style={{ paddingRight: "15px" }}>
              {title}
            </Title>
            <MediaQuery smallerThan={"sm"} styles={{ display: "none" }}>
              <Badge children={"Coming soon"} color="orange" size="sm" />
            </MediaQuery>{" "}
          </Grid>
          <Grid
            align={"center"}
            style={{
              fontFamily: "Roboto, sans-serif !important",
              fontSize: "18px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "normal",
              color: "#fff",
              marginTop: "16px",
              marginBottom: "16px",
            }}
          >
            {description}
          </Grid>
          <div className="ac-toc-wrapper">
            <div className="ac-toc-divider" />
            <div className="ac-toc-text">Tap to open</div>
          </div>
        </div>
      </Tooltip>
    );
  } else {
    return (
      <a
        className="ac-outline glow-on-hover no-bg white-text-on-hover"
        href={url}
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: "none" }}
      >
        <Grid align={"center"} style={{ marginTop: "-20px" }}>
          <Title order={2} style={{ paddingRight: "25px" }}>
            {emoji}
          </Title>
          <Title order={3} style={{ paddingRight: "15px" }}>
            {title}
          </Title>
          <MediaQuery smallerThan={"sm"} styles={{ display: "none" }}>
            <Badge children={"Live"} color="green" size="sm" />
          </MediaQuery>
        </Grid>
        <Grid
          align={"center"}
          style={{
            fontFamily: "Roboto, sans-serif !important",
            fontSize: "18px",
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            color: "#fff",
            marginTop: "16px",
            marginBottom: "16px",
          }}
        >
          {description}
        </Grid>
        <div className="ac-toc-wrapper">
          <div className="ac-toc-divider" />
          <div className="ac-toc-text">Tap to open</div>
        </div>
      </a>
    );
  }
};
