import React from 'react';
import './App.css';
import { Page } from './components/Page/Page';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBVyy7RGY91dwnre7UP3yCHmTJAOG6pqAY",
  authDomain: "solworks-landing-page.firebaseapp.com",
  projectId: "solworks-landing-page",
  storageBucket: "solworks-landing-page.appspot.com",
  messagingSenderId: "584338330113",
  appId: "1:584338330113:web:15cea273adc15e5603a600",
  measurementId: "G-7L0XD7NY6D"
};

function App() {
  const app = initializeApp(firebaseConfig);
  // eslint-disable-next-line
  const analytics = getAnalytics(app);

  return (
    <div className='wrapper' style={{maxWidth: '840px'}}>
      <Page />
    </div>
  );
}

export default App;
