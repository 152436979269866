export const SolanaLogo = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="121"
      height="18"
      viewBox="0 0 121 18"
      style={{ paddingLeft: "10px" }}
    >
      <defs>
        <clipPath id="mg9cll11za">
          <path
            data-name="Rectangle 19"
            transform="translate(-.318 -.207)"
            style={{ fill: "#797f91" }}
            d="M0 0h121v18H0z"
          />
        </clipPath>
      </defs>
      <g
        transform="translate(.318 .207)"
        style={{ clipPath: "url(#mg9cll11za)" }}
      >
        <path
          data-name="Path 28"
          d="m21.684 14.129-3.308 3.547a.765.765 0 0 1-.56.245H2.135a.385.385 0 0 1-.282-.646l3.3-3.547a.765.765 0 0 1 .56-.245H21.4a.383.383 0 0 1 .286.646zm-3.308-7.144a.773.773 0 0 0-.56-.245H2.135a.385.385 0 0 0-.282.646l3.3 3.549a.773.773 0 0 0 .56.245H21.4a.385.385 0 0 0 .28-.646zM2.135 4.437h15.68a.765.765 0 0 0 .56-.245L21.684.645A.383.383 0 0 0 21.4 0H5.718a.765.765 0 0 0-.56.245l-3.3 3.547a.385.385 0 0 0 .282.646z"
          transform="translate(-1.507 .003)"
          style={{ fill: "#797f91" }}
        />
        <path
          data-name="Path 29"
          d="M162.189 16.525H153.8v-2.762h10.569V11h-10.6A2.757 2.757 0 0 0 151 13.742v2.8a2.757 2.757 0 0 0 2.772 2.746h8.4v2.763H151.2v2.763h10.989a2.757 2.757 0 0 0 2.772-2.742v-2.8a2.757 2.757 0 0 0-2.772-2.746z"
          transform="translate(-122.895 -8.944)"
          style={{ fill: "#797f91" }}
        />
        <path
          data-name="Path 30"
          d="M249.2 11h-8.42a2.757 2.757 0 0 0-2.78 2.742v8.329a2.757 2.757 0 0 0 2.78 2.742h8.42a2.757 2.757 0 0 0 2.772-2.742v-8.329A2.757 2.757 0 0 0 249.2 11zm-.021 11.051H240.8v-8.289h8.376z"
          transform="translate(-193.655 -8.943)"
          style={{ fill: "#797f91" }}
        />
        <path
          data-name="Path 31"
          d="M408.136 11h-8.214a2.757 2.757 0 0 0-2.772 2.742v11.071h2.8v-4.538h8.176v4.538h2.8V13.741a2.757 2.757 0 0 0-2.79-2.741zm-.021 6.513h-8.176v-3.75h8.176z"
          transform="translate(-323.096 -8.943)"
          style={{ fill: "#797f91" }}
        />
        <path
          data-name="Path 32"
          d="M583.286 11h-8.214a2.757 2.757 0 0 0-2.772 2.742v11.072h2.8v-4.538h8.158v4.538h2.8V13.742A2.757 2.757 0 0 0 583.286 11zm-.028 6.513h-8.176v-3.75h8.176z"
          transform="translate(-465.55 -8.944)"
          style={{ fill: "#797f91" }}
        />
        <path
          data-name="Path 33"
          d="M495.347 22.051h-1.12l-4-9.894A1.846 1.846 0 0 0 488.513 11h-2.485a1.839 1.839 0 0 0-1.848 1.829v11.985h2.8V13.763h1.12l4 9.894a1.852 1.852 0 0 0 1.717 1.15h2.483a1.839 1.839 0 0 0 1.848-1.829V11h-2.806z"
          transform="translate(-393.88 -8.944)"
          style={{ fill: "#797f91" }}
        />
        <path
          data-name="Path 34"
          d="M328.9 11h-2.8v11.071a2.757 2.757 0 0 0 2.781 2.742h8.4v-2.762H328.9z"
          transform="translate(-265.309 -8.944)"
          style={{ fill: "#797f91" }}
        />
      </g>
    </svg>
  );